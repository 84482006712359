//------------------------------------------------------------------------------------------------------------
// This is a theme file. it must use css variables to be able to override the base theme
:root {
  .wealthfluent-theme {
    --secondary: #00AEEF;
    --sidebar: rgb(245, 245, 245);
    --primary: #1B75BC;
    --primary-light: hsl(from var(--primary) h s calc(l + 15)); // tint
    --primary-dark: hsl(from var(--primary) h s calc(l - 15)); // shade
    --primary-bg: hsl(from var(--primary) h s calc(l + 10)); // tint
    --optimizer-blue: var(--primary-dark);
    --optimizer-accent: #39B54A;
    --logo-height: 5rem;
    --logo-padding: 0.5rem 0;
    --sidebar-toggle-top: 12rem;
    --sidebar-width: 0;
    --sidebar-dark: hsl(from var(--secondary) h s calc(l - 15));;
    --sidebar-text: #00AEEF;
  }
}
